<template>
  <div
    class="c-app"
    :class="{ 'c-dark-theme': $store.state.darkMode }"
    v-if="role.id"
  >
    <TheSidebar />
    <TheAside />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./RoleSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import TheAside from "./TheAside";
import { DataManager, Query } from "@syncfusion/ej2-data";
import DrfAdaptor from "../http/EJ2_adapter";
import { apiUrl } from "../http";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside,
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
  },

  created() {
    let role = this.$store.state.role;
    let role_id = this.$route.params.id;
    if (role && role.id != role_id) {
      const dm = new DataManager({
        url: apiUrl(`/api/auth/roles/${role_id}`),
        adaptor: new DrfAdaptor(),
        crossDomain: true,
      });
      dm.executeQuery(new Query()).then((e) => {
        this.$store.commit("setRole", e.result);
      });
    }
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
